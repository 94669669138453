<template>
    <div>   
        <CForm ref="form" class="needs-validation"   >
            <span style="display: none;"> {{ productionGenerateItem }} </span>
            <CRow>
                <CCol>
                    <CSelect
                        horizontal
                        label="Üretici"
                        placeholder="Üretici"
                        :disabled="disabledAreas"
                        :value.sync="data.productionCompany"
                        :options="productionCompanies"
                        @change="getOrderList"
                        required
                    />
                </CCol>
                <CCol>
                    <CSelect
                        horizontal
                        label="Sipariş No"
                        placeholder="Sipariş No"
                        :disabled="disabledAreas"
                        :value.sync="data.orderNo"
                        :options="orders"
                        @change="getModelList"
                        required
                    />
                </CCol>
            </CRow>
            
            <CRow>
                <CCol>
                    <CInput
                        label="Sevk No"
                        type="number"
                        horizontal
                        v-model="data.shipmentNumber"
                        required
                    />
                </CCol>
                <CCol>
                    <CSelect
                        horizontal
                        :disabled="disabledAreas"
                        label="Cinsiyet"
                        placeholder="Cinsiyet"
                        :options="genderList"
                        :value.sync="data.gender"
                        @change="getModelList"
                        required
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CSelect
                        horizontal
                        label="Model No"
                        placeholder="Model No"
                        :disabled="disabledAreas"
                        :options="models"
                        :value.sync="data.modelNo"
                        required
                    />
                </CCol>
                <CCol>
                    <CInput
                        label="Üretici Kodu"
                        type="text"
                        horizontal
                        v-model="data.producerCode"
                    />
                </CCol>
            </CRow>
           
            <CInput
                :disabled="disabledAreas"
                label="Genel Toplam"
                type="number"
                horizontal
                v-model="data.finalTotal"
            >
            </CInput>
        </CForm>
            <hr/>
            <CDataTable
                v-if="actionType !== 'create'"
                :items="packingItems"
                :fields="packingListFields"
                :loading="loading"
                hover
                class="modalTable"
                v-on:refresh="productionPackingList"
            >
              <template #edit="{item}">
                <td>
                  <span style="color: darkcyan; font-weight: bold; cursor: pointer;"
                        @click="additionalEvent(item, 'update')">Düzenle</span>
                </td>
              </template>
              <template #delete="{item}">
                <td>
                    <span style="color: red; font-weight: bold; cursor: pointer;" @click="additionalEvent(item, 'destroy')">
                        Sil
                    </span>
                </td>
              </template>
            </CDataTable>
    </div>
</template>

<script>
   import 'vue-multiselect/dist/vue-multiselect.min.css'
   export default{
       name: "PackingForm",
       props:{
            additionalEvent: Function,
            params: Object,
            actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create'){
                   this.data = {};
                   this.disabledAreas = false;
               }else{
                this.disabledAreas = true;
               }
           },
       },
       computed:{
        loading(){
            return this.$store.getters.productionLoading
        },
        packingItems: function() {
            return this.$store.getters.productionPackingItems
        },
        productionCompanies: function() {
            let data = ['Üretici Seç'];
            this.$store.getters.productionCompanies.map(pc => data.push({label: pc.name, value: pc.id}))
            return data
        },
        orders: function() {
            let data = ['Sipariş No Seç'];
            this.$store.getters.productionCompanyOrders.map(i => data.push({label: i.orderNo, value: i.id}))
            return data
        },
        models: function() {
            let data = ['Model Seç'];
            this.$store.getters.productionModels.map(m => data.push({label: m.productName, value: m.productId}))
            return data
        },
        productionGenerateItem: function(){
            this.createTableHeader()
          return this.$store.getters.productionGenerateItem
        }
       },
    
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               disabledAreas: this.actionType !== 'create',
               packingListFields: [],
                genderList: ["ERKEK", "KADIN", "UNISEX"],
               loggedUser: JSON.parse(localStorage.getItem('user')).loggedUser

           }
       },
       methods: {
           createTableHeader: function(){
                this.packingListFields = [];
                this.packingListFields.push({key: 'packingNumber', label: "Koli No"})
                this.packingListFields.push({key: 'shipmentLocation', label: "Sevk Yeri"})
                this.$store.getters.productionGenerateItem.map(item => {
                    this.packingListFields.push({key: item.name, label: item.name, _style: item.amount == 0 && 'color: red'})
                })
                this.packingListFields.push({key: 'totalAmount', label: "Toplam Adet"})
                this.packingListFields.push({key: 'packingCode', label: "Koli Kodu"})
                this.packingListFields.push({key: 'edit', label: "Düzenle"})
                this.packingListFields.push({key: 'delete', label: "Sil"})
           },
           getOrderList: async function(){
                this.$store.state.Production.productionModels = []
                await this.$store.dispatch('productionCompanyOrder_list', {productionCompanyId: this.data.productionCompany})
           },
           getModelList: async function(){
                if(this.data.orderNo && this.data.gender){
                    let params = {
                        productionOrderId: this.data.orderNo,
                        gender: this.data.gender
                    }
                    await this.$store.dispatch('productionModel_list', params)
                }else{
                    this.$store.state.Production.productionModels = []
                }
           },
           async productionPackingList() {
                await this.$store.dispatch('productionPackingItem_list', {packingId: this.data.id})
            },
       }
   }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>